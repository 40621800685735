import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import ReactGA from "react-ga";
import { navigate } from "gatsby";
import Button from "../Button";
import AutomaticDownloadButton from "../AutomaticDownloadButton";
import useAuth from "../../hooks/useAuth";
import { WORKOUT_FORMAT, FileFormat } from "../../models/workouts";
import createWorkout from "../../api/createWorkout";
import Loader from "../Loader";
import "../../styles/WorkoutGenerator.css";
import generateSnackbar from "../../helpers/generateSnackbar";
import createInAppWorkout from "../../api/createInAppWorkout";
import WorkoutContext from "../../context/WorkoutContext";
import useGetUserSubscription from "../../hooks/useGetUserSubscription";

interface Props {
  workoutFormat: WORKOUT_FORMAT;
  exerciseId?: string;
}

function automaticDownload(href: string) {
  const downloadButton = document.getElementById(
    "automatic-download-button"
  )! as HTMLAnchorElement;

  downloadButton.href = href;
  downloadButton.click();
}

function WorkoutGenerator(props: Props) {
  const { exerciseId, workoutFormat } = props;
  const { data } = useGetUserSubscription();

  const {
    getAccessToken,
    getUserId,
    getAuthenticationState,
    tokenRenewalComplete
  } = useAuth();
  const { startWorkout } = useContext(WorkoutContext);
  const [isRetrievingWorkout, setRetrievingState] = useState(false);

  const isLoggedIn = getAuthenticationState();

  function handleSurpassDailyLimit(dailyLimit: number) {
    generateSnackbar({
      type: "error",
      message: `You can create up to ${dailyLimit} workout(s) per day`,
      actionMessage: "Upgrade",
      handleAction: () => navigate("/pricing")
    });

    return setRetrievingState(false);
  }

  function handleUnauthorisedUse() {
    generateSnackbar({
      type: "error",
      message: `Downloading your workout is a premium feature.`,
      actionMessage: "Upgrade",
      handleAction: () => navigate("/pricing")
    });

    return setRetrievingState(false);
  }

  function handleGenericWorkoutProblem() {
    generateSnackbar({
      message: "We had a problem creating your workout",
      hideAfter: 10000,
      type: "error"
    });

    return setRetrievingState(false);
  }

  async function downloadWorkout() {
    const userId = getUserId();

    setRetrievingState(true);

    const body = {
      userId,
      workoutFormat,
      exerciseId,
      fileFormat: "pdf" as FileFormat
    };

    try {
      const res = await createWorkout(getAccessToken(), body);

      if (res.status === 403) {
        return handleUnauthorisedUse();
      }

      if (res.status >= 400) {
        return handleGenericWorkoutProblem();
      }

      const data = await res.json();

      ReactGA.event({
        category: "Workout Generator",
        action: "Generated a workout",
        label: `Generated a ${workoutFormat} workout as pdf`
      });

      automaticDownload(data.s3URL);
      setRetrievingState(false);
    } catch (e) {
      console.error(e);
      return handleGenericWorkoutProblem();
    }
  }

  async function performWorkout() {
    const requestBody = {
      userId: getUserId(),
      workoutFormat,
      exerciseId
    };

    try {
      const res = await createInAppWorkout(getAccessToken(), requestBody);

      if (res.status === 403) {
        const { dailyLimit } = await res.json();
        return handleSurpassDailyLimit(dailyLimit);
      }

      if (res.status >= 400) {
        return handleGenericWorkoutProblem();
      }

      const data = await res.json();

      ReactGA.event({
        category: "Workout Generator",
        action: "Generated a workout",
        label: `Generated a custom in-app workout`
      });

      setRetrievingState(false);
      startWorkout(data.workout);
      navigate("/workout");
    } catch (e) {
      console.error(e);
      return handleGenericWorkoutProblem();
    }
  }

  if (!tokenRenewalComplete) {
    return <Loader />;
  }

  if (!isLoggedIn)
    return (
      <div className="WorkoutGenerator">
        <p>
          You need to <Link to="/login/">Sign up</Link> to create a workout
        </p>
      </div>
    );

  if (!data?.subscription?.active) {
    return (
      <div className="WorkoutGenerator">
        <p data-testId="premium-upsell">
          You need <Link to="/pricing">Cali Skills Premium</Link> to get access
          to our Advanced Workout Builder.
        </p>
      </div>
    );
  }

  return (
    <div className="WorkoutGenerator">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="WorkoutGenerator__action-bar">
          <Button disabled={isRetrievingWorkout} handleClick={downloadWorkout}>
            Download the workout
          </Button>
          <Button disabled={isRetrievingWorkout} handleClick={performWorkout}>
            Perform the workout
          </Button>
          <AutomaticDownloadButton />
        </div>
        <h3>
          <Link to="/workouts/workouts-list">Back to workouts</Link>
        </h3>
      </div>
    </div>
  );
}

export default WorkoutGenerator;
