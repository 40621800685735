import React from "react";
import ReactGA from "react-ga";

interface Props {
  link: string;
  children: string;
}

function AffiliateLink(props: Props) {
  return (
    <>
      <a
        onClick={() => {
          ReactGA.event({
            action: `User clicked the affiliate link for ${props.children}`,
            label: "User click an affiliate link",
            category: "Affiliates"
          });
        }}
        target="_blank"
        href={props.link}
      >
        {props.children}
      </a>
    </>
  );
}

export default AffiliateLink;
