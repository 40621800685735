import { FileFormat } from "../models/workouts";

interface Body {
  userId: string;
  workoutFormat: string;
  fileFormat: FileFormat;
}

function createWorkout(accessToken: string, body: Body) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/workouts`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export default createWorkout;
