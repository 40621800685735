import { WORKOUT_FORMAT } from "../models/workouts";

interface Body {
  userId: string;
  workoutFormat: WORKOUT_FORMAT;
  exerciseId?: string;
}

function createInAppWorkout(accessToken: string, body: Body) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/workouts/in-app`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export default createInAppWorkout;
