export type FileFormat = "in-app" | "pdf";

export enum WORKOUT_FORMAT {
  recommendedRoutine = "recommended-routine",
  firstPullUpsAndBeyond = "first-pull-ups-and-beyond",
  improveYourIsometricHolds = "improve-your-isometric-holds"
}

export const WORKOUTS = {
  "recommended-routine": "Recommended Routine",
  "first-pull-ups-and-beyond": "First Pull Ups and Beyond",
  "improve-your-isometric-holds": "Improve Your Isometric Holds"
};
