import React from "react";
import TextPageLayout from "../../components/layouts/TextPageLayout";
import SEO from "../../components/SEO";
import { Link } from "gatsby";
import { OutboundLink } from "react-ga";
import { PullUpBarAffiliateLink } from "../../components/affiliates";
import WorkoutGenerator from "../../components/workoutGenerators/WorkoutGenerator";
import { WORKOUT_FORMAT } from "../../models/workouts";

function FirstPullUpsAndBeyond() {
  return (
    <TextPageLayout title="First Pull Ups and Beyond">
      <SEO
        path="/workouts/first-pull-ups-and-beyond/"
        title="Achieve the Pull Up with First Pull Ups and Beyond"
        description="Use a mixture of different back exercises and achieve the Pull Up, a milestone calisthenics skill. Once you've mastered the Pull Up, increase your max rep count."
      />
      <p>First Pull Ups and Beyond is a workout format designed to help you:</p>
      <ul style={{ listStyle: "circle", marginLeft: "16px" }}>
        <li>Achieve your very first Pull Up.</li>
        <li>Improve your max rep count.</li>
      </ul>
      <WorkoutGenerator workoutFormat={WORKOUT_FORMAT.firstPullUpsAndBeyond} />
      <p>
        This workout format employs different training principles to help you
        reach your Pull Up goals. For more details about performing a Pull Up
        with perfect form, you can check out our{" "}
        <Link to="/exercises/pull-up/">Pull Up exercise page</Link>. The only
        equipment necessary to perform this workout is a{" "}
        <PullUpBarAffiliateLink>Pull Up Bar</PullUpBarAffiliateLink>.
      </p>
      <h2>First Pull Ups</h2>
      <p>
        This workout focuses on the various Pull Up regressions. Pull Up
        regressions are exercises that work the same muscles as the Pull Ups but
        are less challenging. These exercises include:
      </p>
      <ul style={{ listStyle: "circle", marginLeft: "16px" }}>
        <li>The Negative Pull Up</li>
        <li>Ring Rows/Inverted Rows</li>
        <li>Scapular Pulls</li>
      </ul>
      <p>
        Once the Pull Up is marked as complete in your Skill Tree, we will start
        generating the Beyond First Pull Ups workout.
      </p>
      <h2>Beyond First Pull Ups</h2>
      <p>
        The Beyond First Pull Ups workout focuses on helping you improve your
        max rep count. The workout uses various workout techniques to achieve
        this. These techniques include:
      </p>
      <ul style={{ listStyle: "circle", marginLeft: "16px" }}>
        <li>Regressions</li>
        <li>Pyramid sets</li>
        <li>Supersets</li>
        <li>Training to failure</li>
      </ul>
      <p>
        A pyramid set is a technique used to increase the number of Pull Ups
        performed without reaching failure. For someone whose max reps for a
        Pull Up is 6, a pyramid set will look like this:
      </p>
      <p>
        1 rep → rest → 2 reps → rest → ... → 5 reps → rest → 4 reps → rest → ...
        → 1 rep.
      </p>
      <p>
        The number of Pull Ups performed increments but never reaches your
        maximum. Halfway through the pyramid set, the number of reps starts
        decreasing back down to 1. As a comparison, performing 3 sets of 5 reps
        will mean completing 15 Pull Ups. But performing the above pyramid set
        means you'll have completed 25 Pull Ups. A lot of programs aimed at
        improving Pull Up rep count focus on gradually{" "}
        <OutboundLink
          eventLabel="to StrongFirst"
          target="_blank"
          to="https://www.strongfirst.com/the-fighter-pullup-program-revisited/"
        >
          increasing the number of reps
        </OutboundLink>
        .
      </p>
      <p>
        A superset is a technique used to work a muscle after training to
        failure. This means performing as many reps for a given exercise, then
        perform a regression immediately after without resting. When you can no
        longer perform an exercise with good form, move on to the regression. If
        your max rep count for Pull Ups is 3, a superset might look like the
        following:
      </p>
      <p>3 Pull Ups → 4 Negative Pull Ups → rest → repeat 2 more times.</p>
      <p>
        Training to failure is{" "}
        <OutboundLink
          eventLabel="to low vs high Load resistance training journal"
          target="_blank"
          to="https://journals.lww.com/nsca-jscr/Fulltext/2015/10000/Effects_of_Low__vs__High_Load_Resistance_Training.36.aspx"
        >
          recognised as a great way for improving muscle size and endurance
        </OutboundLink>
        .
      </p>
    </TextPageLayout>
  );
}

export default FirstPullUpsAndBeyond;
