import React from "react";

function Loader() {
  return (
    <div data-testid="loader" className="Loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default Loader;
