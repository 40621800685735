/* eslint jsx-a11y/anchor-is-valid: 0, jsx-a11y/anchor-has-content: 0 */

import React from "react";

function AutomaticDownloadButton() {
  return (
    <a
      data-testid="download-button"
      id="automatic-download-button"
      download=""
      style={{ display: "none" }}
    />
  );
}

export default AutomaticDownloadButton;
