import React from "react";
import AffiliateLink from "../base/AffiliateLink";

interface Props {
  children: string;
}

export function RingsAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B00MHAUDM6/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B00MHAUDM6&linkCode=as2&tag=calistheni02b-21&linkId=dfd7d2f818af5364db10cd7e9c85a5c7"
      children={props.children}
    />
  );
}

export function BandsAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B01LZXVNC2/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B01LZXVNC2&linkCode=as2&tag=calistheni02b-21&linkId=e3dcf8b14c0e8d75a33bd4f47a219c69"
      children={props.children}
    />
  );
}

export function PullUpBarAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B00VPMRY06/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B00VPMRY06&linkCode=as2&tag=calistheni02b-21&linkId=e84500d0b7e7ad828e6ba1944867e73e"
      children={props.children}
    />
  );
}

export function DipStationAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B085HTK99C/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B085HTK99C&linkCode=as2&tag=calistheni02b-21&linkId=88cbaa10e7bfa456f58ae6e75cde81c5"
      children={props.children}
    />
  );
}

export function AbWheelAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B00AZZES0S/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B00AZZES0S&linkCode=as2&tag=calistheni02b-21&linkId=30c35307faf9a10d96d4738c3d69dd50"
      children={props.children}
    />
  );
}

export function ParallettesAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B07VMSXJT1/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B07VMSXJT1&linkCode=as2&tag=calistheni02b-21&linkId=58248fd264e2ec95ec3092685631a19c"
      children={props.children}
    />
  );
}

export function ChalkAffiliateLink(props: Props) {
  return (
    <AffiliateLink
      link="https://www.amazon.co.uk/gp/product/B07RRRCRDW/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B07RRRCRDW&linkCode=as2&tag=calistheni02b-21&linkId=004a0d20cf90c065b386ff0f6fede3d0"
      children={props.children}
    />
  );
}
